/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import ReactDOM from "react-dom";
import axios from 'axios';



import "./formrelation.css";

class DatabaseForm extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      values: {
        Email: "",
        Name: "",
        Reference: "",
        Url: "",
        file: null
      },
      isSubmitting: false,
      isError: false,
      message: ""
    };
  }
  

  submitForm = async e => {
    e.preventDefault();
    let size = this.state.file.size;
    let extension = this.state.file.type;
    console.log(size);
    console.log(extension);
    if( extension != 'application/pdf'){
      this.setState({ message: 'Invalid file type. Only pdf files are allowed', isError: true })
      console.log(this.state.message);
      return;
    }
    if( size > 3000000){
      this.setState({ message: 'Maximum pdf file size: 3MB. Please compress your file before submitting it.', isError: true })
      console.log(this.state.message);
      return;
    }
    this.setState({ isSubmitting: true });
    console.log(this.state.file);
    const datafile = new FormData()

    datafile.append("files", this.state.file)

    const upload_res = await axios({
      method: 'POST',
      url: 'https://api.thebridgedatabase.com/upload',
      data: datafile
    })

    const strapi_url = 'https://api.thebridgedatabase.com/uploads/'+upload_res.data[0].hash+upload_res.data[0].ext;
    console.log(strapi_url)

    const val = this.state.values;
    val['file'] = strapi_url;


    
    

    const res = await fetch("https://api.thebridgedatabase.com/generic-fragility-curves", {
      method: "POST",
      body: JSON.stringify(val),
      headers: {
        "Content-Type": "application/json"
      }
    });
    this.setState({ isSubmitting: false });
    const data = await res.json();
    console.log(data);
    !data.hasOwnProperty("error")
      ? this.setState({ message: data.success })
      : this.setState({ message: data.error, isError: true });

    setTimeout(
      () =>
        this.setState({
          isSubmitting: false,
          isError: false,
          message: "Uploaded succesfully. Thank you for your entry.",
          values: { Email: "", Reference: "", Name: "", Url: "" }
        }),
      1600
    );
  };
      
  handleInputChange = e =>{
    this.setState({
      values: { ...this.state.values, [e.target.name]: e.target.value }
    });
    console.log(this.state.values);
  }
  handleInputChangeFile = e =>{
    this.setState({
      file:e.target.files[0]
    });
    console.log(this.state.file);
  }
  

 
    
  
    
    render(){
        
        
      return(
          <>
        <form className="contact-form form-database" onSubmit={this.submitForm}>
        <label>
        Full Name:
        <input
            type="text"
            name="Name"
            id="name"
            value={this.state.values.Name || ""}
            onChange={this.handleInputChange}
            placeholder="Your Full Name"
            title="Name"
            required
        />
        </label>
        <label>
        Email:
        <input
            type="email"
            name="Email"
            id="email"
            value={this.state.values.Email || ""}
            onChange={this.handleInputChange}
            title="Email"
            placeholder="Your Email"
            required
        />
        </label>
        <label>
        Reference:
        <input
            type="text"
            name="Reference"
            id="message"
            value={this.state.values.Reference || ""}
            placeholder="eg. Avsar, O. Yakut, A., Caner, A. (2011)"
            onChange={this.handleInputChange}
            title="Reference"
            required
        />
        </label>
        <label>
        Url (optional):
        <input
            type="text"
            name="Url"
            id="url"
            placeholder="eg. https://doi.org/10.1193/1.3651349"
            value={this.state.values.Url || ""}
            onChange={this.handleInputChange}
            title="Url"
        />
        </label>
        <label>
        Pdf File (max 3MB):
        <input
            type="file"
            name="Pdf"
            id="pdf"
            value={this.state.values.Pdf || ""}
            onChange={this.handleInputChangeFile}
            title="Pdf"
        />
        </label>
        <button type="submit">Submit</button>
    </form>
    <div className={`message ${this.state.isError && "error"}`}>
      {this.state.isSubmitting ? "Submitting..." : this.state.message}
    </div>
    </>
      )
    }
}
export default DatabaseForm
