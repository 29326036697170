import React from "react"
import DatabaseForm from "../components/databaseform"
import $ from 'jquery';

import Layout from "../components/layout"
import SEO from "../components/seo"
import SVGIcon from "../images/pdf-file.svg"
import WordIcon from "../images/word.svg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import useSiteMetadata from '../hooks/use-site-metadata';
import PDFTable from "../images/table.pdf"
import { graphql } from 'gatsby'





const DatabasePage = ({ data }) => {
  if (typeof window !== 'undefined') {
    $(document).ready(function () {
      $('#referencelist').children('li').addClass('optionshow');
      $('#bridgeclass').change(function() {
        var value = $(this).val();
        if(value == 'all'){
          $('#referencelist').children('li').addClass('optionshow');
        }else{
          $('#referencelist').children('li').removeClass('optionshow');
          $('#referencelist').children('li.'+value).addClass('optionshow');

        }
      });
    });
  }
  //javascript
  const title = 'Generic Fragility Curves <br />(curves for typological bridge classes)';
  const metatitle = 'Generic Fragility Curves (curves for typological bridge classes)';
  const description = 'Meta Description for The Generic Fragility Curves';
  const metadescription = description;
  const siteMeta = useSiteMetadata();
  let curves = data.allStrapiGenericFragilityCurves.nodes;

  const codearray = [];
  curves.map((curve, index)=>{
    let codesnew = curve.Codesnew;
    codesnew.map((code, index)=>{
      let x1 = code.X1;
      let x2 = code.X2;
      let x3 = code.X3;
      let codestring = x1+x2+x3;
      codearray.indexOf(codestring) === -1 ? codearray.push(codestring) : console.log("This item already exists");
    })
  })
  codearray.sort();

  function reference(e){
    let pdf = e.target.dataset.pdf;
    console.log(pdf);
    if(e.target.nodeName == 'IMG'){
      pdf = e.target.parentElement.dataset.pdf;
      console.log(e.target.parentElement.dataset);
      console.log(pdf);
    }
    window.open(pdf);
  }
  const executeScroll = (e) => {
    e.preventDefault();
    let targetid = e.target.dataset.target
    console.log();
    let targetdom = document.getElementById(targetid);
    console.log(targetdom);
    let top = targetdom.offsetTop;
    console.log(top);

		window.scrollTo({
      top: top,
      behavior: 'smooth'
    });
  }
  function Anchor(props) {
    console.log(props.url);
    let anchor;
    if(props.url!=''){
      anchor = <><a className="pdflink" target="_blank" href={props.url}>View publication<FontAwesomeIcon icon={faChevronRight} /></a></>;
    }else{
      anchor = <></>
    }
    return anchor;
  }
  return(
    <Layout bodyclass="database" title={title}>
      <SEO
        title={metatitle}
        description={metadescription}
      />
      <div className="referencenav">
        <button id="referencenav1" data-target="referencelist" onClick={ e => executeScroll(e)}>Fragility Curve Catalogue</button><button onClick={ e => executeScroll(e)} id="referencenav2" data-target="referenceform">Contribute your Fragility Curves</button>
      </div>

      <div className="selectclass">
        <h4>Select Bridge Class</h4>
        <div className="bridgefilter">
          <select id="bridgeclass">
            <option key='all' value="all">All</option>
            {codearray.map((line,index)=>{
              return(
                <option key={index} value={line}>{line}</option>
              )
            })}
          </select>
          <span>Bridge Class X<sub>1</sub>X<sub>2</sub>X<sub>3</sub> (<a href="https://link.springer.com/article/10.1007/s10518-008-9077-2" target="_blank">ASPROGE, Moschonas et al.,2008)</a><a className="info-table" href={PDFTable} target="_blank">Info</a></span>
        </div>
      </div>
      <ul id="referencelist">
        {curves.map((curve, index)=>{

          let codesnew = curve.Codesnew;
          let finalcode = '';
          let bridgeclass_string = '';
          
          codesnew.map((code, index)=>{
            let x1 = code.X1;
            let x2 = code.X2;
            let x3 = code.X3;
            finalcode = finalcode+x1+x2+x3+' ';
            bridgeclass_string = bridgeclass_string+x1+x2+x3+', ';
          })
          bridgeclass_string = bridgeclass_string.slice(0, -2);
          
          
          return(
            <li key={index} className={finalcode}>
              <div className="ref-left">
              <button data-pdf={curve.file} onClick={reference}>{index+1}. {curve.Reference} 
              <img alt="Pdf Document" src={SVGIcon} /></button>
              <Anchor url={curve.Url} />
              </div>
              <div className="ref-right">
                <span>Bridge Classes</span>
              {bridgeclass_string}
              </div>
            </li>
          )
        })}
      </ul>
      
        <div id="referenceform">
          <h2>Contribute your fragility curves</h2>
          <p style={{marginBottom: 30}}>You can contribute your fragility curves to the database. Please use the proposed format. <a href="../images/ProposedFormat.docx" target="_blank" download><img alt="Word Document" src={WordIcon} /></a></p>
          <DatabaseForm />
        </div>

      
    </Layout>
  )
}

export default DatabasePage

export const query = graphql`
  query Fragilitycurves {
    allStrapiGenericFragilityCurves(filter: {Publish: {eq: true}}, sort: {fields: strapiId, order: ASC}) {
      nodes {
        Reference
        Url
        file
        strapiId
        Codesnew{
          X1
          X2
          X3
        }
      }
    }
}
`